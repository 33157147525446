<template>
  <div
    style="
      display: flex;
      flex-flow: wrap;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    "
  >
    <!--    <div class="py-4 text-h4">Delivery Form</div>-->
    <form id="createForm" class="pa-6 pt-0 pb-0 row elevation-0">
      <div class="col-sm-6 col-md-6 col-lg-4 col-12">
        <v-autocomplete
          v-model="formData.warehouse_id"
          label="Warehouse"
          item-text="text"
          item-value="index"
          :items="serverData.warehouses"
          clearable
          outlined
          :error-messages="warehouse_idErrors"
          @input="$v.formData.warehouse_id.$touch()"
          @blur="$v.formData.warehouse_id.$touch()"
        ></v-autocomplete>
      </div>
      <div class="col-sm-6 col-md-6 col-lg-4 col-12">
        <v-select
          v-model="formData.order_date"
          label="Invoicing order date"
          item-text="text"
          item-value="index"
          :items="serverData.order_dates"
          clearable
          outlined
          :error-messages="order_dateErrors"
          @input="$v.formData.order_date.$touch()"
          @blur="$v.formData.order_date.$touch()"
        ></v-select>
      </div>
      <div class="col-sm-6 col-md-6 col-lg-4 col-12">
        <v-select
          v-model="formData.order_types"
          label="Order types"
          item-text="text"
          item-value="index"
          :items="serverData.order_types"
          single-line
          multiple
          clearable
          outlined
          :error-messages="order_typesErrors"
          @input="$v.formData.order_types.$touch()"
          @blur="$v.formData.order_types.$touch()"
        ></v-select>
      </div>

      <div class="col-sm-6 col-md-6 col-lg-4 col-12">
        <v-text-field
          v-model="formData.cod_price"
          label="Cod price"
          clearable
          outlined
          type="Number"
          min="0"
          @change="() => validateMinValue('cod_price', 0)"
        ></v-text-field>
      </div>
      <div class="col-sm-6 col-md-6 col-lg-4 col-12">
        <v-text-field
          v-model="formData.cod_percentage"
          label="Cod percentage"
          clearable
          outlined
          type="Number"
          min="0"
          @change="() => validateMinValue('cod_percentage', 0)"
        ></v-text-field>
      </div>
      <div class="col-sm-6 col-md-6 col-lg-4 col-12">
        <v-text-field
          v-model="formData.rate"
          label="Base rate"
          clearable
          outlined
          type="Number"
          min="0"
          @change="() => validateMinValue('rate', 0)"
          :error-messages="rateErrors"
          @input="$v.formData.rate.$touch()"
          @blur="$v.formData.rate.$touch()"
        ></v-text-field>
      </div>
      <div class="col-sm-6 col-md-6 col-lg-4 col-12">
        <v-text-field
          v-model="formData.tax_percentage"
          label="Tax percentage"
          clearable
          outlined
          type="Number"
          min="0"
          @change="() => validateMinValue('tax_percentage', 0)"
          :error-messages="tax_percentageErrors"
          @input="$v.formData.tax_percentage.$touch()"
          @blur="$v.formData.tax_percentage.$touch()"
        ></v-text-field>
      </div>
      <div class="col-12 d-flex flex-wrap justify-center align-center">
        <div class="col-sm-6 col-md-6 col-lg-6 col-12">
          <v-radio-group
            label="Remote area mode"
            v-model="formData.remote_area_type"
          >
            <v-radio label="Multiplier" :value="0"></v-radio>
            <v-radio label="Fixed rate" :value="1"></v-radio>
          </v-radio-group>
        </div>
        <div class="col-sm-6 col-md-6 col-lg-6 col-12">
          <v-text-field
            v-model="formData.remote_area"
            :label="remoteAreaValueLabel"
            clearable
            outlined
            type="Number"
            min="0"
            @change="() => validateMinValue('remote_area', 0)"
          ></v-text-field>
        </div>
      </div>
      <div class="col-12 d-flex flex-wrap justify-center align-center">
        <div class="col-sm-6 col-md-6 col-lg-6 col-12">
          <v-radio-group label="Discount" v-model="formData.discount_type">
            <v-radio label="Flat rate" :value="0"></v-radio>
            <v-radio label="Percentage" :value="1"></v-radio>
          </v-radio-group>
        </div>
        <div class="col-sm-6 col-md-6 col-lg-6 col-12">
          <v-text-field
            v-model="formData.discount"
            :label="discountValueLabel"
            clearable
            outlined
            type="Number"
            min="0"
            @change="() => validateMinValue('discount', 0)"
            :error-messages="discountErrors"
            @input="$v.formData.discount.$touch()"
            @blur="$v.formData.discount.$touch()"
          ></v-text-field>
        </div>
      </div>

      <div class="col-12">
        <v-radio-group
          label="Destination"
          v-model="formData.destination_type"
          row
          :error-messages="destination_typeErrors"
          @input="$v.formData.destination_type.$touch()"
          @blur="$v.formData.destination_type.$touch()"
          @change="destinationTypeUpdate"
        >
          <v-radio
            v-for="(desti, i) in serverData.destination_types"
            :label="desti.text"
            :value="desti.index"
            :key="i"
          ></v-radio>
        </v-radio-group>
        <div v-if="destination_type === 0">
          <v-autocomplete
            v-model="formData.destination_id"
            label="Country"
            item-text="text"
            item-value="index"
            :items="serverData.countries"
            clearable
            outlined
            :error-messages="destination_idErrors"
            @input="$v.formData.destination_id.$touch()"
            @blur="$v.formData.destination_id.$touch()"
          ></v-autocomplete>
        </div>
        <div v-else-if="destination_type === 1">
          <v-autocomplete
            v-model="formData.destination_id"
            label="Regions"
            item-text="text"
            item-value="index"
            :items="serverData.regions"
            clearable
            outlined
            :error-messages="destination_idErrors"
            @input="$v.formData.destination_id.$touch()"
            @blur="$v.formData.destination_id.$touch()"
          ></v-autocomplete>
        </div>
      </div>

      <div class="col-12 pt-8">
        <div
          class="col-12 d-flex align-center justify-center flex-wrap py-0 my-0"
        >
          <div class="col-12 col-sm-6">
            <v-select
              v-model="formData.fulfillment_unit_id"
              label="Fulfillment unit"
              item-text="text"
              item-value="index"
              :items="serverData.fulfillment_units"
              clearable
              outlined
              :error-messages="fulfillment_unit_idErrors"
              @input="$v.formData.fulfillment_unit_id.$touch()"
              @blur="$v.formData.fulfillment_unit_id.$touch()"
            ></v-select>
          </div>
          <div class="col-sm-6 col-12">
            <v-text-field
              v-model="formData.base_fulfillment_picks"
              label="Base fulfillment picks"
              clearable
              outlined
              type="Number"
              min="0"
              @change="() => validateMinValue('base_fulfillment_picks', 0)"
              :error-messages="base_fulfillment_picksErrors"
              @input="$v.formData.base_fulfillment_picks.$touch()"
              @blur="$v.formData.base_fulfillment_picks.$touch()"
            ></v-text-field>
          </div>
        </div>

        <rate-datatable
          name="Fulfillment increment"
          :setSku="setRates"
          :skus="formData.fulfillment_increments"
          :hasImport="true"
          :importType="2"
          importLabel="Import Fulfillment Increment"
        ></rate-datatable>
        <div
          class="col-12 d-flex align-center justify-center flex-wrap py-0 my-0 pt-8"
        >
          <div class="col-sm-6 col-12">
            <v-select
              v-model="formData.delivery_unit_id"
              label="Delivery unit"
              item-text="text"
              item-value="index"
              :items="serverData.delivery_units"
              clearable
              outlined
              :error-messages="delivery_unit_idErrors"
              @input="$v.formData.delivery_unit_id.$touch()"
              @blur="$v.formData.delivery_unit_id.$touch()"
            ></v-select>
          </div>
          <div class="col-sm-6 col-12">
            <v-text-field
              v-model="formData.base_delivery_weight"
              label="Base delivery weight"
              clearable
              outlined
              type="Number"
              min="0"
              @change="() => validateMinValue('base_delivery_weight', 0)"
              :error-messages="base_delivery_weightErrors"
              @input="$v.formData.base_delivery_weight.$touch()"
              @blur="$v.formData.base_delivery_weight.$touch()"
            ></v-text-field>
          </div>
        </div>

        <rate-datatable
          name="Delivery increment"
          :setSku="setIncrements"
          :skus="formData.delivery_increments"
          :hasImport="true"
          :importType="2"
          importLabel="Import Delivery Increment"
        ></rate-datatable>
      </div>

      <v-main class="text-center mt-2 col-12 elevation-0">
        <v-btn class="mx-1" color="primary" @click="submitCreateForm">
          submit
        </v-btn>
        <v-btn class="mx-1" @click="resetCreateForm"> clear </v-btn>
        <v-btn class="mx-1" @click="goBack"> back </v-btn>
      </v-main>
    </form>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import RateDatatable from "@/own/components/finance/services/addForms/rateContainer/RateDatatable";
import { required } from "vuelidate/lib/validators";
import fieldValueValidation from "@/own/mixins/fieldValueValidation.js";
export default {
  name: "DeliveryForm.vue",
  components: { RateDatatable },
  mixins: [validationMixin, fieldValueValidation],
  props: ["setData", "actionFuntion", "serverData", "pageLoader", "initData"],
  validations: {
    formData: {
      destination_id: { required },
      destination_type: { required },
      order_types: { required },
      order_date: { required },
      warehouse_id: { required },
      tax_percentage: { required },
      rate: { required },
      delivery_unit_id: { required },
      fulfillment_unit_id: { required },
      discount: { required },
      base_fulfillment_picks: { required },
      base_delivery_weight: { required },
    },
  },
  data: () => ({
    formData: {
      id: null,
      warehouse_id: null,
      rate: null,

      destination_type: null,
      destination_id: null,
      cod_price: null,
      cod_percentage: null,

      tax_percentage: null,
      order_date: null,
      order_types: null,

      fulfillment_increments: [],
      delivery_increments: [],
      remote_area_type: 0,
      remote_area: null,
      fulfillment_unit_id: null,
      delivery_unit_id: null,
      discount: null,
      discount_type: 0,
      base_fulfillment_picks: null,
      base_delivery_weight: null,
    },
  }),
  methods: {
    destinationTypeUpdate() {
      this.formData.destination_id = null;
    },
    setIncrements(item) {
      // console.log("before update", this.formData.increments, item);
      let copy = { ...this.formData };
      copy.delivery_increments = [...item];
      this.formData = copy;
      // console.log("after update", this.formData.increments);
    },
    setRates(item) {
      // console.log("before update", this.formData.rates, item);
      let copy = { ...this.formData };
      copy.fulfillment_increments = [...item];
      this.formData = copy;
      // console.log("after update", this.formData.rates);
    },
    goBack() {
      this.actionFuntion("back");
    },

    submitCreateForm() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.pageLoader(true);
      // let data = JSON.stringify(this.entCreate);
      const data = { ...this.formData };
      if (this.formData.rate_type === "manual") {
        data.rates_file = null;
      }
      if (this.formData.increment_type === "manual") {
        data.increments_file = null;
      }
      this.setData(data);
      this.actionFuntion();
    },
    resetCreateForm() {
      this.$v.$reset();
      if (this.initData) {
        try {
          let copy = { ...this.serverData.service.values };
          Object.entries(this.formData).forEach((localData) => {
            Object.entries(this.serverData.service.values).forEach(
              (serverData) => {
                if (localData[0] == serverData[0]) {
                  this.formData[localData[0]] = copy[serverData[0]];
                }
              }
            );
          });
        } catch (e) {
          // console.error(e);
          this.formData = {
            id: null,
            warehouse_id: null,
            rate: null,

            destination_type: null,
            destination_id: null,
            cod_price: null,
            cod_percentage: null,

            tax_percentage: null,
            order_date: null,
            order_types: null,

            fulfillment_increments: [],
            delivery_increments: [],
            remote_area_type: 0,
            remote_area: null,
            fulfillment_unit_id: null,
            delivery_unit_id: null,
            discount: null,
            discount_type: 0,
            base_fulfillment_picks: null,
            base_delivery_weight: null,
          };
        }
      } else {
        this.formData = {
          id: null,
          warehouse_id: null,
          rate: null,

          destination_type: null,
          destination_id: null,
          cod_price: null,
          cod_percentage: null,

          tax_percentage: null,
          order_date: null,
          order_types: null,

          fulfillment_increments: [],
          delivery_increments: [],

          remote_area_type: 0,
          remote_area: null,
          fulfillment_unit_id: null,
          delivery_unit_id: null,
          discount: null,
          discount_type: 0,
          base_fulfillment_picks: null,
          base_delivery_weight: null,
        };
      }
    },
    handleFormValidation(fieldName, vueObj) {
      const errors = [];
      if (!vueObj.$v.formData[fieldName].$dirty) return errors;
      if ("required" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      if ("url" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].url &&
          errors.push("This url is invalid");
      }
      if ("numeric" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].numeric &&
          errors.push("This must be a number");
      }
      if ("email" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].email &&
          errors.push("This email address is invalid");
      }
      return errors;
    },
  },
  computed: {
    remoteAreaValueLabel() {
      return this.formData.remote_area_type == "0"
        ? "Multiplier"
        : "Fixed rate";
    },
    discountValueLabel() {
      return this.formData.discount_type == 0 ? "Flat rate" : "Percentage";
    },
    destination_type: function () {
      return this.formData.destination_type;
    },
    destination_idErrors() {
      return this.handleFormValidation("destination_id", this);
    },
    destination_typeErrors() {
      return this.handleFormValidation("destination_type", this);
    },
    order_typesErrors() {
      return this.handleFormValidation("order_types", this);
    },

    order_dateErrors() {
      return this.handleFormValidation("order_date", this);
    },
    tax_percentageErrors() {
      return this.handleFormValidation("tax_percentage", this);
    },
    fulfillment_unit_idErrors() {
      return this.handleFormValidation("fulfillment_unit_id", this);
    },
    delivery_unit_idErrors() {
      return this.handleFormValidation("delivery_unit_id", this);
    },
    warehouse_idErrors() {
      return this.handleFormValidation("warehouse_id", this);
    },
    rateErrors() {
      return this.handleFormValidation("rate", this);
    },
    // nameErrors() {
    //   return this.handleFormValidation("name", this);
    // },
    discountErrors() {
      return this.handleFormValidation("discount", this);
    },
    base_fulfillment_picksErrors() {
      return this.handleFormValidation("base_fulfillment_picks", this);
    },
    base_delivery_weightErrors() {
      return this.handleFormValidation("base_delivery_weight", this);
    },
  },
};
</script>
